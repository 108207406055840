<template>
  <div class="reports d-flex">
    <v-navigation-drawer>
      <template #prepend>
        <v-list-item two-line>
          Отчеты
        </v-list-item>
      </template>

      <v-divider />

      <v-list dense>
        <template v-for="(report, idx) in allowedReports">
          <v-list-item
            v-if="report !== null"
            :key="report.value"
            :class="isActive(report)"
            @click="select(report.value)"
          >
            <v-list-item-content class="pointer">
              <v-list-item-title>{{ report.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider
            v-else
            :key="`divider-${idx}`"
            class="my-1"
          />
        </template>
      </v-list>
    </v-navigation-drawer>
    <div class="reports__body">
      <div
        v-show="!activeComponent"
        class="noComponent"
      >
        <p>
          Выберите отчет чтобы продолжить
        </p>
      </div>
      <component
        :is="activeComponent"
        :params="params"
      />
    </div>
  </div>
</template>
<script>

export default {
  data:() => ({
    activeComponent:null,
    params:{
      item:null,
    },
    reports:[
      {
        name:'Утилизация',
        value:'utilization',
        component:() => import('@/components/reports/utilization.vue')
      },
      {
        name:'Нагрузка специалистов отдела',
        value:'employeeEfficiency',
        component:() => import('@/components/reports/employee-efficiency.vue')
      },
      {
        name:'Использование обеспечения',
        value:'paymentEnsuring',
        rules:['rep.sostatistics.create'],
        component:() => import('@/components/reports/payment-ensuring.vue')
      },
      {
        name:'Оформленные документы',
        value:'completedDocuments',
        rules:['rep.div.admin.create'],
        component:() => import('@/components/reports/completed-documents.vue')
      },
    ],
  }),
  computed:{
    allowedReports(){
      return this.reports.filter((report) => {
        return report?.rules?.length ? this.$allowed(report.rules) : true
      })
    },
  },
  created(){
    this.openDefaultComponent()
  },
  methods:{
    isActive(item){
      return item.value === this.activeComponentName ? 'teal lighten-1': ''
    },
    openDefaultComponent(){
      const q = this.$route.query
      if(Object.keys(q).length && q?.list){
        this.select(q.list)
      }
    },
    select(code){
      const {component, value} = this.reports.find(i => i?.value === code)
      this.activeComponentName = value
      this.activeComponent = component ?? null
    },
  }
}
</script>
<style scoped>
  .pointer{
    cursor: pointer;
  }
  .reports{
    width:100%;
    min-height: calc(100vh - 129px)
  }
  .reports__body{
    width: 100%;
  }
  .noComponent{
    width: 100%;
    height: 100%;
    display: flex;
    align-items:center;
    justify-content: center;
  }
</style>
